import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Box } from 'rebass';
import BookingArtist from '../components/BookingArtist';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/plyr.css';

class ArtistPageTemplate extends PureComponent {
  render() {
    const {
      data: { artist },
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>{artist.title}</title>
        </Helmet>
        <ToastContainer />
        <Header
          activePageName="BOOKING"
          rightText={`DISCOVER ${artist.title.toUpperCase()}`}
        />
        <Box id="content" mt={[35, 70]}>
          <BookingArtist
            artist={artist}
            expandedMode
            isManagementPage={artist.showOnManagementPage}
          />
        </Box>
        <Footer activePageName="BOOKING" />
      </div>
    );
  }
}

ArtistPageTemplate.propTypes = {
  data: PropTypes.shape({
    artist: PropTypes.shape({
      biography: PropTypes.string.isRequired,
      bookingAgents: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      bookingPageAttributes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      musicWidget: PropTypes.shape({
        artistId: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
      pressKitUrl: PropTypes.string,
      showOnManagementPage: PropTypes.bool.isRequired,
      socialLinks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      title: PropTypes.string.isRequired,
      youtubeIframeUrl: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query artist($id: String!) {
    artist: artistsJson(id: { eq: $id }) {
      id
      title
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      biography
      bookingAgents {
        email
        title
      }
      managementAgents {
        email
        title
      }
      bookingPageAttributes {
        title
        value
      }
      managementPageAttributes {
        title
        value
      }
      pressKitUrl
      musicWidget {
        type
        artistId
      }
      socialLinks {
        name
        url
      }
      youtubeIframeUrl
      showOnManagementPage
    }
  }
`;

export default ArtistPageTemplate;
